import React from "react"
import { Link } from "gatsby"
import Curve3 from "../svgs/shapes/curves/curve-3.svg"
import Image from "./Image"

const BlogPostPreview = ({ blogPost }) => {
  return (
    <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">
      <Link className="card-img-top" to={`/de/blog/${blogPost.slug}/`}>
        <img
          src={
            blogPost.preview_image.localFile.childImageSharp.gatsbyImageData
              .images.fallback.src
          }
          alt={blogPost.title}
          className="card-img-top"
        />

        <div className="position-relative">
          <div className="shape shape-bottom shape-fluid-x text-white">
            <Curve3 />
          </div>
        </div>
      </Link>

      <Link className="card-body" to={`/de/blog/${blogPost.slug}/`}>
        <h3 dangerouslySetInnerHTML={{ __html: blogPost.title }} />

        <p
          className="mb-0 text-muted"
          dangerouslySetInnerHTML={{
            __html: blogPost.content.data.content.slice(0, 150).concat("..."),
          }}
        />
      </Link>

      <Link className="card-meta mt-auto" to={`/de/blog/${blogPost.slug}/`}>
        <hr className="card-meta-divider" />

        <div className="avatar avatar-sm me-2">
          <Image
            src="albino_cipolla_bg.jpg"
            className="avatar-img rounded-circle"
            alt="Albino Cipolla"
          />
        </div>

        <h6 className="text-uppercase text-muted me-2 mb-0">Albino Cipolla</h6>

        <p className="h6 text-uppercase text-muted mb-0 ms-auto">
          <time dateTime={blogPost.createdAt}>{blogPost.createdAt}</time>
        </p>
      </Link>
    </div>
  )
}

export default BlogPostPreview
