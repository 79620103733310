import React, { useContext, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import HelmetHead from "../components/HelmetHead"
import LayoutContext from "../components/LayoutContext"
import Image from "../components/Image"
import { marked } from "marked"
import { iframeResize } from "iframe-resizer"

import Curve1 from "../svgs/shapes/curves/curve-1.svg"
import Curve3 from "../svgs/shapes/curves/curve-3.svg"
import BlogPostPreview from "../components/BlogPostPreview"

export const query = graphql`
  query BlogPostQuery($id: Int!) {
    strapiBlogPost(strapi_id: { eq: $id }) {
      id
      slug
      title
      createdAt(formatString: "DD. MMMM, YYYY", locale: "de")
      content {
        data {
          content
        }
      }
      preview_image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        keywords
      }
    }
    allStrapiBlogPost(limit: 3) {
      edges {
        node {
          id
          slug
          title
          createdAt(formatString: "DD. MMMM, YYYY", locale: "de")
          content {
            data {
              content
            }
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

const BlogPost = ({ data, location }) => {
  const blogPost = data.strapiBlogPost
  const blogPosts = data.allStrapiBlogPost.edges

  const [layout, setLayout] = useContext(LayoutContext)

  const mzIframe = useRef(null)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: { bgColor: "white", color: "light" },
    }))
    iframeResize({ log: false }, mzIframe.current)
  }, [])

  return (
    <>
      <HelmetHead
        title={blogPost.seo.metaTitle}
        description={blogPost.seo.metaDescription}
        keywords={blogPost.seo.keywords}
      />

      {/*<section*/}
      {/*  data-jarallax*/}
      {/*  data-speed=".8"*/}
      {/*  className="py-12 py-md-15 bg-cover jarallax"*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(${blogPost.preview_image.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`,*/}
      {/*  }}*/}
      {/*/>*/}

      <section className="pt-12">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-9 col-xl-8">
              <h1
                className="display-4 text-center"
                dangerouslySetInnerHTML={{ __html: blogPost.title }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-6 pt-md-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-9 col-xl-8">
              <figure className="figure">
                <img
                  className="figure-img img-fluid rounded lift lift-lg"
                  src={
                    blogPost.preview_image.localFile.childImageSharp
                      .gatsbyImageData.images.fallback.src
                  }
                  alt={blogPost.title}
                />

                {/*<figcaption className="figure-caption text-center">*/}
                {/*  This is a caption on this photo for reference*/}
                {/*</figcaption>*/}
              </figure>

              {blogPost.seo.keywords && (
                <div className="row align-items-center">
                  <div className="col-auto">
                    <h6 className="fw-bold text-uppercase text-muted mb-0">
                      Tags:
                    </h6>
                  </div>
                  <div className="col ms-n5">
                    {blogPost.seo.keywords.split(",").map((kw, i) => (
                      <div
                        className="badge rounded-pill bg-secondary-soft me-1"
                        key={i}
                      >
                        <span className="h6 text-uppercase">{kw}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="row align-items-center my-7 py-5 border-top border-bottom">
                <div className="col-auto">
                  <div className="avatar avatar-lg">
                    <Image
                      src="albino_cipolla_bg.jpg"
                      className="avatar-img rounded-circle"
                      alt="Albino Cipolla"
                    />
                  </div>
                </div>
                <div className="col ms-n5">
                  <h6 className="text-uppercase mb-0">Albino Cipolla</h6>

                  <time
                    className="fs-sm text-muted"
                    dateTime={blogPost.createdAt}
                  >
                    Veröffentlicht am {blogPost.createdAt}
                  </time>
                </div>
                <div className="col-auto">
                  <span className="h6 text-uppercase text-muted d-none d-md-inline me-4">
                    Social-Media:
                  </span>

                  <ul className="d-inline list-unstyled list-inline list-social">
                    <li className="list-inline-item list-social-item me-3">
                      <a
                        href="https://www.facebook.com/Maklerzentrale/"
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <img
                          src="https://landkit.goodthemes.co/assets/img/icons/social/facebook.svg"
                          className="list-social-icon"
                          alt="Maklerzentrale Facebook"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <p
                className="mb-0 blog-post-content"
                dangerouslySetInnerHTML={{
                  __html: marked(blogPost.content.data.content),
                }}
              />
            </div>
            <div className="col-lg-4 mt-8 mt-lg-0">
              <div className="sticky-lg-top" style={{ top: "120px" }}>
                <div className="card card-border shadow-sm overflow-hidden mb-5 pb-3">
                  <iframe
                    ref={mzIframe}
                    src={`/de/iframe/immobilienbewertung/?r=${location.href}`}
                    frameBorder="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-6 pt-md-8 pb-8 pb-md-11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-9 col-xl-8">
              <div className="row align-items-center py-5 border-top border-bottom">
                <div className="col-auto">
                  <div className="avatar avatar-lg">
                    <Image
                      src="albino_cipolla_bg.jpg"
                      className="avatar-img rounded-circle"
                      alt="Albino Cipolla"
                    />
                  </div>
                </div>
                <div className="col ms-n5">
                  <h6 className="text-uppercase mb-0">Albino Cipolla</h6>

                  <time
                    className="fs-sm text-muted"
                    dateTime={blogPost.createdAt}
                  >
                    Veröffentlicht am {blogPost.createdAt}
                  </time>
                </div>
                <div className="col-auto">
                  <span className="h6 text-uppercase text-muted d-none d-md-inline me-4">
                    Social-Media:
                  </span>

                  <ul className="d-inline list-unstyled list-inline list-social">
                    <li className="list-inline-item list-social-item me-3">
                      <a
                        href="https://www.facebook.com/Maklerzentrale/"
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <img
                          src="https://landkit.goodthemes.co/assets/img/icons/social/facebook.svg"
                          className="list-social-icon"
                          alt="Maklerzentrale Facebook"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-light">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 py-md-10 bg-light">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-12 col-sm">
              <h3 className="mb-0">Neueste Beiträge</h3>

              {/*<p className="mb-0 text-muted">*/}
              {/*  Here’s what we've been up to recently.*/}
              {/*</p>*/}
            </div>
            <div className="col-12 col-sm-auto mt-4 mt-sm-0">
              <Link to="/de/blog/" className="btn btn-sm btn-outline-gray-300">
                Blog der Maklerzentrale
              </Link>
            </div>
          </div>
          <div className="row">
            {blogPosts.map(({ node: blogPost }) => (
              <div className="col-12 col-md-6 d-flex" key={blogPost.id}>
                <BlogPostPreview blogPost={blogPost} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogPost
